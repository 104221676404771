import { IsEnum, IsUUID } from "class-validator";
import { ERole } from "@/enums/roles";

export default class RoleRole {
  @IsUUID("4")
  public readonly id?: string | null;

  @IsEnum(ERole)
  public name?: ERole | string;

  constructor(data?: any) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
    }
  }

  public get translated_name(): string | undefined {
    switch (this.name) {
      case ERole.supervisor:
        return "Руководитель";
      case ERole.trainer:
        return "Тренер-наставник";
      case ERole.curator:
        return "Наставник для нового сотрудника";
    }
    return this.name;
  }
}
