export default {
  users: `/v1/users/users`,
  users_tree: `/v1/users/users/tree`,
  users_tree_search: `/v1/users/users/tree_search`,
  trainers: `v1/users/users/trainers`,
  user: (id: string) => `/v1/users/users/${id}`,
  skills_diff: (id: string) => `/v1/users/skills/${id}/diff`,
  skills_target: (id: string) => `/v1/users/skills/${id}/target`,
  skills_current: (id: string) => `/v1/users/skills/${id}/current`,
  skillsMe: `/v1/users/skills/me`,
  userMe: `/v1/users/users/me`,
  subscriptionsMe: `/v1/users/subscriptions/me`,
  team: `/v1/users/teams`,
  post_team_xlsx: `/v1/users/teams/xlsx`,
  position: `/v1/users/teams/positions`,
  calendar: (id: string) => `/v1/users/users/calendar/${id}`,
  unconfirmedEvents: (id: string) =>
    `/v1/users/users/calendar/unconfirmedEvents/${id}`,
  calendar_new: `/v1/users/users/calendar`,
  signs: (id: string) => `/v1/users/users/${id}/signs`,
  workplace_exists: (id: string) => `/v1/users/users/${id}/workplace_exists`,
  supervisors_all: (id: string) => `/v1/users/supervisors/${id}/all`,
  supervisors_line: (id: string) => `/v1/users/supervisors/${id}/line`,
  curator_users: (id: string) => `/v1/users/curators/${id}/users`,
  curator_curators: (id: string) => `/v1/users/curators/${id}/curators`,
  supervisors_functional: (id: string) =>
    `/v1/users/supervisors/${id}/functional`,
  testing_assignments: (id: string) =>
    `/v1/users/users/${id}/testing_assignments`,
  workplaces: `/v1/users/users/workplaces`,
  user_workplaces: (id: string) => `/v1/users/${id}/workplaces`,
  workplacesMe: `/v1/users/workplaces/me`,
  skills: `/v1/users/skills`,
  calendar_sync: `/v1/users/calendar_sync`,
  delegates: `/v1/users/delegates`,
  delegate: (id: string) => `/v1/users/delegates/${id}`
};
